import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { TagKey as SRTagKey } from '@cof/tag-catalog-white-label-ui';
import { useEffect } from 'react';
import '@/components/TimeoutModal/TimeoutModal.scss';
import clockImage from '@/components/TimeoutModal/clockImage';
import { publishTag } from '@/services/tagging-service';
export default ({ handleSignInClick, handleClose, dealer }) => {
    useEffect(() => {
        const timeoutModal = document.getElementById('timeout-modal');
        timeoutModal?.addEventListener('modal-dialog-close', (e) => {
            publishTag(SRTagKey.SESSION_EXPIRED_EXIT, undefined, () => {
                handleClose();
            });
        });
        // Set custom colors for dealer
        timeoutModal?.style.setProperty('--fs-color-interactive-primary-default', dealer?.theme?.main);
        timeoutModal?.style.setProperty('--fs-color-focus-primary-default', dealer?.theme?.main);
        timeoutModal?.style.setProperty('--fs-color-interactive-primary-hover', dealer?.theme?.main);
        timeoutModal?.style.setProperty('--fs-color-interactive-primary-focus', dealer?.theme?.main);
        timeoutModal?.style.setProperty('--fs-color-interactive-primary-active', dealer?.theme?.main);
        document.getElementById('openTimeoutModal')?.click();
    }, [dealer]);
    useEffect(() => {
        publishTag(SRTagKey.VIEW_SESSION_EXPIRED);
    }, []);
    return (_jsx(_Fragment, { children: _jsx("div", { className: "timeout-modal", children: _jsxs("fs-modal-dialog", { dismissible: true, "mobile-layout": "auto", id: "timeout-modal", children: [_jsx("button", { className: "fs-button fs-button--primary", id: "openTimeoutModal", style: { display: 'none' } }), _jsx("h2", { className: "modal-dialog-header", slot: "modal-dialog-header" }), _jsx("p", { slot: "modal-dialog-body", "focus-initial": true, children: _jsxs("div", { className: "body", children: [_jsx("div", { id: "clock-image", children: clockImage(dealer?.theme?.main) }), _jsx("p", { id: "session-expired-text", children: "Your session has expired" }), _jsx("p", { id: "session-expired-detailed", children: "We've signed you out due to inactivity to keep your account secure. Please sign in to pick up where you left off." })] }) }), _jsx("fs-modal-dialog-footer", { "footer-layout-swap-breakpoint": "medium", slot: "modal-dialog-footer", children: _jsx("fs-button", { className: "submitButton", onclick: () => {
                                publishTag(SRTagKey.SESSION_EXPIRED_SIGN_IN, undefined, () => {
                                    handleSignInClick();
                                });
                            }, id: "signInButton", children: "Sign in" }) })] }) }) }));
};
