export var Product;
(function (Product) {
    Product[Product["Showroom"] = 0] = "Showroom";
    Product[Product["RemarketingLandingPage"] = 1] = "RemarketingLandingPage";
})(Product || (Product = {}));
export var CustomerType;
(function (CustomerType) {
    CustomerType[CustomerType["PQ"] = 0] = "PQ";
    CustomerType[CustomerType["VISITOR"] = 1] = "VISITOR";
    CustomerType[CustomerType["CRM"] = 2] = "CRM";
    CustomerType[CustomerType["EIDPNPQ"] = 3] = "EIDPNPQ";
    CustomerType[CustomerType["UNKNOWN"] = 4] = "UNKNOWN";
})(CustomerType || (CustomerType = {}));
