import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { MFEAdapter } from '@cof/mfe-adapter';
import { mfeAdapter as unifiedCalculatorMFEAdapter } from '@cof/mfe-contract-unified-calculator-web-ui-v3';
import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import vinValidator from 'vin-validator';
import { MFEComponentDefinitions } from '@/MFEComponentDefinitions';
import TimeoutModal from '@/components/TimeoutModal/TimeoutModal';
import { DealerContext } from '@/context/DealerContext';
import { VehicleMetadataContext } from '@/context/MetadataContext';
import { TaggingContext } from '@/context/TaggingContext';
import { UserContext } from '@/context/UserContext';
import { Product } from '@/models/product';
import ConfirmationPage from '@/pages/ConfirmationPage/ConfirmationPage';
import ErrorPage from '@/pages/ErrorPage/ErrorPage';
import LoadingPage from '@/pages/LoadingPage/LoadingPage';
import MuseLandingPageLazy from '@/pages/MusePremium/Loadable';
import PrequalPageLazy from '@/pages/PrequalPage/Loadable';
import SearchResultsPageLazy from '@/pages/SearchResultsPage/Loadable';
import VehicleDetailsPageLazy from '@/pages/VehicleDetailPage/Loadable';
import { serviceGetContainer } from '@/services/landing-page-service';
import { initTealium, instantiateStratum, publishTag, setExternalVar } from '@/services/tagging-service';
import { initNewRelicData, logNewRelicAction } from '@/utils/newRelic';
import '@/components/fs-components';
import { PrefillDataContext } from '@/context/PrefillDataContext';
import { getLeadSource } from '@/utils/sourceId';
import { getLoginUrl } from '@/services/login-service';
import { fetchInterceptor } from '@/services/interceptor-service';
// Setup Fetch Interceptor for SB environments
const { prefix = '' } = window.ENV_DATA || {};
if (prefix) {
    fetchInterceptor();
}
/* Available templateTypes */
export var TemplateTypes;
(function (TemplateTypes) {
    TemplateTypes["PREQUAL"] = "prequal";
    TemplateTypes["VEHICLESHARE"] = "vehicleshare";
    TemplateTypes["FEEDBACKTODEALERCONFIRMATION"] = "feedbacktodealerconfirmation";
    TemplateTypes["MUSEPREMIUM"] = "musesalespersonpage";
})(TemplateTypes || (TemplateTypes = {}));
/** @returns LID/UUID, if it's in the correct form, or undefined */
export const sanitizeLID = (lid) => /^[\da-f]{8}-[\da-f]{4}-[\da-f]{4}-[\da-f]{4}-[\da-f]{12}$/i.test(lid) ? lid : undefined;
/** @returns LID/UUID from url, i.e /?lid=345634, or undefined */
export const processParamLID = () => sanitizeLID(new URLSearchParams(location.search).get('lid') ?? '');
export const processParamVIN = () => {
    const vin = new URLSearchParams(location.search).get('vin') ?? '';
    return vinValidator.validate(vin) ? vin : undefined;
};
export const getNewRoute = (metadata) => {
    if (metadata === undefined)
        return '';
    if (metadata.deeplink?.templateType?.toLowerCase() === TemplateTypes.VEHICLESHARE)
        return '/vehicle';
    if (metadata.deeplink?.templateType?.toLowerCase() === TemplateTypes.PREQUAL)
        return '/prequalify';
    if (metadata.deeplink?.templateType?.toLowerCase() === TemplateTypes.FEEDBACKTODEALERCONFIRMATION)
        return '/confirmation';
    if (metadata.deeplink?.templateType?.toLowerCase() === TemplateTypes.MUSEPREMIUM)
        return '/muse';
    return '';
};
export const getSearchParams = (vehicleData) => {
    const searchParams = new URLSearchParams(window.location.search);
    if (vehicleData?.vin) {
        searchParams.set('vin', vehicleData.vin);
    }
    return searchParams.toString();
};
initNewRelicData(); // Initialize New Relic
export default () => {
    const lid = processParamLID(); // Get LeadID query param
    if (lid === undefined) {
        instantiateStratum(Product.Showroom, () => {
            setExternalVar('applicationId', 'showroom');
        });
    }
    else {
        instantiateStratum(Product.RemarketingLandingPage, () => {
            setExternalVar('applicationId', lid);
        });
    }
    const [error, setError] = useState(); // undefined=loading, null=success, string=error
    const [metadata, setMetadata] = useState();
    const [vehicleData, setVehicleData] = useState({});
    const [feedbackOptions, setFeedbackOptions] = useState({});
    const [dealerName, setDealerName] = useState();
    const [dealer, setDealer] = useState({});
    const dealerContextValue = { dealer, setDealer };
    const [vehicleMetadata, setVehicleMetadata] = useState({});
    const vehicleMetadataContextValue = { vehicleMetadata, setVehicleMetadata };
    const [envData, setEnvData] = useState(window.ENV_DATA);
    const [showTimeoutModal, setShowTimeoutModal] = useState(false);
    const [user, setUser] = useState();
    const userContextValue = { user, setUser };
    const [product, setProduct] = useState(lid === undefined ? Product.Showroom : Product.RemarketingLandingPage);
    const [templateType, setTemplateType] = useState(undefined);
    const productContextValue = { product, setProduct, templateType, setTemplateType };
    const [prefillData, setPrefillData] = useState({});
    const [showroomBrandingEnabled] = useState(lid === undefined);
    const [dapEnabled] = useState(lid === undefined);
    const processVehicleShareData = (data) => {
        setMetadata(data);
        setVehicleMetadata(data);
        if (data.deeplink.visitorId) {
            setUser({ customerId: data.deeplink.visitorId });
        }
        else if (data.deeplink.crmLeadId) {
            setUser({ customerId: data.deeplink.crmLeadId });
        }
        else {
            setUser({});
        }
        if (Object.keys(data.vehicle).length === 0) {
            setExternalVar('vin', data.deeplink.vin);
        }
        else {
            setExternalVar('vin', data.vehicle?.vin);
            setExternalVar('dealerId', data.vehicle?.dealerId);
        }
        setError(null);
        unifiedCalculatorMFEAdapter.initializeMFE(window.ENV_DATA.calculatorMfeEnv);
        window.dispatchEvent(new Event('lrm-page-load-success'));
        logNewRelicAction("get-deep-link" /* ACTION.GET_METADATA */, { event: 'success' });
    };
    const handleSignInClick = () => {
        window.open(getLoginUrl({
            dealerIds: dealer?.dealerIds,
            shortName: dealer?.shortName,
            webApplicationId: dealer?.webApplicationId,
            lid: lid ?? null,
            user,
            state: undefined,
            customerReferenceId: undefined,
            vin: undefined
        }), '_self');
    };
    const processPrequalificationData = (data) => {
        setMetadata(data);
        if (Object.keys(data.eligibility).length === 0) {
            const leadSource = getLeadSource(metadata?.deeplink?.sourceId ?? '');
            publishTag("PQ_PAGE_VIEW_LOAD_ERROR" /* LRMTagKey.PQ_PAGE_VIEW_LOAD_ERROR */, { replacements: { value: leadSource } });
            setError('generic');
        }
        else {
            setMetadata(data);
            setError(null);
            window.dispatchEvent(new Event('lrm-page-load-success'));
        }
        logNewRelicAction("get-deep-link" /* ACTION.GET_METADATA */, { event: 'success' });
    };
    const processFeedbackToDealerConfirmation = (data) => {
        setMetadata(data);
        setDealerName(data.dealerName);
        if (data.deeplink.LandingPageContentText === undefined)
            setError('notfound');
        else
            setError(null);
    };
    const fetchMetadata = (vin) => {
        if (lid) {
            serviceGetContainer(lid, vin)
                .then(data => {
                initTealium(data);
                setTemplateType(data?.deeplink?.templateType);
                if (data.deeplink.templateType.toLowerCase() === TemplateTypes.PREQUAL) {
                    processPrequalificationData(data);
                }
                else if (data.deeplink.templateType.toLowerCase() === TemplateTypes.VEHICLESHARE) {
                    processVehicleShareData(data);
                }
                else if (data.deeplink.templateType.toLowerCase() === TemplateTypes.FEEDBACKTODEALERCONFIRMATION) {
                    processFeedbackToDealerConfirmation(data);
                }
                else if (data.deeplink.templateType.toLowerCase() === TemplateTypes.MUSEPREMIUM) {
                    setMetadata(data);
                    setError(null);
                }
                else {
                    throw Error(`unknown templateType: ${data.deeplink.templateType}`);
                }
            })
                .catch(e => {
                // TODO: HOW DO WE STILL SHOW SHOWROOM IF THIS HAPPENS?
                // deeplink call itself failed, we don't know scenario
                if (!lid)
                    publishTag("VDP_PAGE_VIEW_ERROR" /* LRMTagKey.VDP_PAGE_VIEW_ERROR */);
                logNewRelicAction("get-deep-link" /* ACTION.GET_METADATA */, { event: 'fail', error: e.message });
                setError('generic');
            });
        }
        else {
            setError(null);
        }
    };
    const setPrefillContext = (formData) => {
        setPrefillData({
            ...prefillData,
            address: formData?.address?.value,
            aptUnit: formData?.unit?.value,
            birthday: formData?.dateOfBirth?.value,
            city: formData?.city?.value,
            confirmDetails: formData?.consent?.value,
            email: formData?.email?.value,
            firstName: formData?.firstName?.value,
            lastName: formData?.lastName?.value,
            phoneNumber: formData?.cellPhone?.value,
            state: formData?.state?.value,
            zipCode: formData?.zipCode?.value
        });
    };
    const handleTimeoutModalClosure = () => {
        location.reload();
        setShowTimeoutModal(false);
    };
    useEffect(() => {
        const vin = processParamVIN();
        logNewRelicAction("get-deep-link" /* ACTION.GET_METADATA */, { event: 'started' });
        fetchMetadata(vin);
    }, []);
    useEffect(() => {
        if (envData) {
            const componentDefinitions = MFEComponentDefinitions;
            componentDefinitions[MFEComponentDefinitions.findIndex(mfe => mfe.mfeName === 'prequal-mfe')].bundleUrl =
                window.ENV_DATA.prequalMfeUrl;
            componentDefinitions[MFEComponentDefinitions.findIndex(mfe => mfe.mfeName === 'c1-inventory-search')].bundleUrl =
                window.ENV_DATA.searchMfeBundleUrl;
            MFEAdapter.initialize(componentDefinitions);
            MFEAdapter.enableMFEVitals('showroom-mfe-vitals');
        }
    }, [envData]);
    useEffect(() => {
        if (user?.sessionExpired)
            setShowTimeoutModal(true);
    }, [user]);
    useEffect(() => {
        if (dealer?.dealerIds) {
            initTealium(dealer);
        }
    }, [dealer]);
    return (_jsx(_Fragment, { children: error === undefined ? (_jsx(LoadingPage, {})) : error != null ? (_jsx(ErrorPage, { error: error })) : (_jsx(Router, { children: _jsx(TaggingContext.Provider, { value: productContextValue, children: _jsx(DealerContext.Provider, { value: dealerContextValue, children: _jsx(UserContext.Provider, { value: userContextValue, children: _jsxs(PrefillDataContext.Provider, { value: {
                                prefillData,
                                setPrefillData: setPrefillContext
                            }, children: [_jsxs(Routes, { children: [_jsx(Route, { path: "/prequalify", element: lid ? (_jsx(PrequalPageLazy, { deeplink: metadata?.deeplink ?? {}, eligibility: metadata?.eligibility ?? {}, lid: lid })) : (_jsx(ErrorPage, { error: 'generic' })) }), _jsx(Route, { path: "/vehicle", element: lid ? (_jsx(VehicleMetadataContext.Provider, { value: vehicleMetadataContextValue, children: _jsx(VehicleDetailsPageLazy, { dapEnabled: false, featureSet: Product.RemarketingLandingPage, showroomBrandingEnabled: false }) })) : (_jsx(ErrorPage, { error: 'generic' })) }), _jsx(Route, { path: "/muse", element: _jsx(MuseLandingPageLazy, { metadata: metadata }) }), _jsx(Route, { path: "/confirmation", element: _jsx(ConfirmationPage, { metadata: metadata }) }), _jsx(Route, { path: "/", element: _jsx(Navigate, { to: { pathname: getNewRoute(metadata), search: getSearchParams(vehicleData) }, replace: true }) }), _jsx(Route, { path: "/:shortname", element: _jsx(SearchResultsPageLazy, { dapEnabled: dapEnabled, showroomBrandingEnabled: showroomBrandingEnabled }) }), _jsx(Route, { path: "/:shortname/vehicle-details/:year/:make/:model/:vin", element: _jsx(VehicleDetailsPageLazy, { dapEnabled: dapEnabled, featureSet: Product.Showroom, showroomBrandingEnabled: showroomBrandingEnabled }) }), _jsx(Route, { path: "/:shortname/*", element: _jsx(ErrorPage, { error: "notfound" }) }), _jsx(Route, { path: "/error", element: _jsx(ErrorPage, { error: "notfound" }) })] }), showTimeoutModal && dealer?.theme && (_jsx(TimeoutModal, { handleSignInClick: handleSignInClick, handleClose: handleTimeoutModalClosure, dealer: dealer }))] }) }) }) }) })) }));
};
