export const MFEComponentDefinitions = [
    {
        bundleUrl: 'https://coaf-prequalification-qa.clouddqtext.capitalone.com/v1/prequal-form.umd.bundle.min.js',
        mfeName: 'prequal-mfe',
        version: '1.0.0',
        tags: [
            {
                attributes: [
                    'bffBaseUrl',
                    'customerData',
                    'dealer',
                    'isDealerShareMarketplace',
                    'decisionCtaText',
                    'decisionScreensAreEnabled',
                    'env',
                    'externalId',
                    'fraudQuizIsEnabled',
                    'idpType',
                    'isMLPMarketplace',
                    'ownerId',
                    'personalPageHeaderText',
                    'preapproval',
                    'prequalId',
                    'sourceId'
                ],
                cssProperties: [],
                events: ['mfe-analytics'],
                tag: 'c1-auto-prequal-form',
                properties: []
            }
        ]
    },
    {
        bundleUrl: 'https://coaf-lo-consumer-search-web-ui-qa.clouddqtext.capitalone.com/inventory-ui.esm.bundle.js',
        mfeName: 'c1-inventory-search',
        version: '1.0.0',
        tags: [
            {
                attributes: [
                    'assetsPath',
                    'bffBaseUrl',
                    'cardConfigurations',
                    'clientCorrelationId',
                    'featureFlags',
                    'environment',
                    'imageTypeForNewVehicles',
                    'imageTypeForUsedVehicles',
                    'lenderIds',
                    'prequalId',
                    'pricingSystemOverride',
                    'searchFilters',
                    'sourceSystem',
                    'sortByOrderBlocklist',
                    'vehicleConditionOptions'
                ],
                cssProperties: [],
                events: [
                    'filters-changed',
                    'finance-terms-changed',
                    'get-prequalified',
                    'search-mfe-ready',
                    'vehicle-clicked',
                    'unlock-pricing'
                ],
                tag: 'c1-inventory-search',
                properties: []
            }
        ]
    }
];
