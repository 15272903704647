import { constructUrl } from '@/services/service-config';
import { initTealium, publishTag, setExternalVar } from '@/services/tagging-service';
import { getClientCorrelationId } from '@/utils/correlation-id';
import { getLeadSource } from '@/utils/sourceId';
const baseURL = '/protected/958910/auto/container/';
const getTenantId = () => new URLSearchParams(window.location.search).get('tid');
const buildSearchParamString = (inputParams) => {
    const params = new URLSearchParams(inputParams);
    const tid = getTenantId();
    !tid || params.set('tenantId', tid); // if (tid) then add it to the params
    const paramString = params.toString();
    return paramString.length ? `?${paramString}` : '';
};
export const serviceGetContainer = async (lid, vin) => {
    if (process.env.NODE_ENV !== 'production')
        if (new URLSearchParams(location.search).has('mock'))
            (await import('@/services/mock/landing-page-bff-mock')).default(); // Create mock server in dev env
    const res = await fetch(`${constructUrl(baseURL, `link/${lid}/metadata`)}${buildSearchParamString(vin ? { vin } : undefined)}`, {
        headers: {
            Accept: 'application/json;v=1',
            'Client-Correlation-Id': getClientCorrelationId()
        }
    });
    if (res.ok) {
        const returnVal = await res.json();
        return returnVal;
    }
    if (res.status === 404)
        throw Error('404');
    throw Error(`Error occurred while querying ${lid}`);
};
export const servicePostPrequal = async (lid, pqId, sourceId, refId) => {
    const leadSource = getLeadSource(sourceId);
    const res = await fetch(`${constructUrl(baseURL, `prequal/link/${lid}/lead`)}${buildSearchParamString()}`, {
        method: 'POST',
        headers: new Headers({
            Accept: 'application/json;v=1',
            'Content-Type': 'application/json',
            ...(refId && { 'Client-Correlation-Id': refId })
        }),
        body: JSON.stringify({ prequalificationApplicationId: pqId })
    });
    if (!res.ok) {
        publishTag("LEAD_CREATION_ERROR" /* TagKey.LEAD_CREATION_ERROR */, { replacements: { value: leadSource } });
        // TODO: Add New Relic monitoring for failure
    }
    else {
        publishTag("LEAD_CREATED" /* TagKey.LEAD_CREATED */, { replacements: { value: leadSource } });
    }
};
export const getVehicleData = async (vin, dealerId) => {
    if (process.env.NODE_ENV !== 'production')
        if (new URLSearchParams(location.search).has('mock'))
            (await import('@/services/mock/landing-page-bff-mock')).default(); // Create mock server in dev env
    const res = await fetch(`${constructUrl(baseURL, `vin/${vin}/details`)}${buildSearchParamString({ dealerId })}`, {
        headers: {
            Accept: 'application/json;v=1',
            'Content-Type': 'application/json',
            'Client-Correlation-Id': getClientCorrelationId()
        }
    });
    if (res.ok) {
        const responseVehicle = (await res.json());
        initTealium(responseVehicle);
        setExternalVar('vin', responseVehicle.vin);
        return responseVehicle;
    }
    throw Error((await res.json()).developerText);
};
export const sendFeedback = async (lid, vin, feedback) => {
    const res = await fetch(`${constructUrl(baseURL, `link/${lid}/message`)}${buildSearchParamString()}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json;v=1',
            'Content-Type': 'application/json',
            'Client-Correlation-Id': getClientCorrelationId()
        },
        body: JSON.stringify({ customerAction: feedback.feedbackAction, vin, customerFeedback: feedback })
    });
    if (res.ok)
        return res;
    throw Error((await res.json()).developerText);
};
export const getSharedHeaders = async () => {
    const res = await fetch(`${baseURL}auth`, {
        method: 'GET',
        credentials: 'include',
        headers: {
            Accept: 'application/json;v=1',
            'Content-Type': 'application/json',
            'Client-Correlation-Id': getClientCorrelationId()
        }
    });
    //TODO: Figure out if this is EIDPHeaders or FsIdpHeaders
    if (res.ok) {
        const headers = (await res.json());
        headers.C1_FS_MT || (headers.C1_FS_MT = '');
        return headers;
    }
    throw Error((await res.json()).developerText);
};
